import axios from 'axios';
import { getAssigneeQuery } from './queries';

const getAssigneeListDetails = async () => {
    try {
        const assigneeQuery = getAssigneeQuery({
            fields: [
                'account_number',
                'assignee_name',
                'assignee_number',
                'category',
                'deduction_type',
                'flat_amount',
                'gl_number',
                'jurisdiction',
                'milk_type',
                'payment_method',
                'payment_type',
                'producer_id',
                'rate_amount',
                'status',
                'type_of_charge',
                'address1',
                'address2',
                'town',
                'postal_code',
                'state',
                'phone_number',
                'bank_transaction_code',
                'bank_account_number',
                'bank_routing_number',
                'email',
                'classification_code',
                'print_check_detail',
            ],
        });
        const assigneeResponse = await axios.get(assigneeQuery);
        const assignees = assigneeResponse.data;
        return { assignees };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getAssigneeListDetails;
