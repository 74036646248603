import axios from 'axios';
import _ from 'lodash';
import { getProducerSubUsersQuery, getProducersQuery } from './queries';

const getSubProducerListDetails = (includeDeleted, id) =>
    new Promise((resolve, reject) => {
        const fields = ['created_at', 'deleted_at', 'email', 'secondary_email', 'id', 'name', 'permissions', 'producer_id', 'updated_at', 'username'];
        const subUsersQuery = getProducerSubUsersQuery({
            ...(!!id && { producerId: id }),
            fields,
            includeDeleted,
        });
        const subUsersRequest = axios.get(subUsersQuery);

        subUsersRequest
            .then((subUsersResponse) => {
                const subUsers = subUsersResponse.data;
                const ids = _.uniq(_.map(subUsers, 'producer_id'));

                const producersQuery = getProducersQuery({ fields: ['id', 'name'], ids });
                const producersRequest = axios.get(producersQuery);

                producersRequest.then((producersResponse) => {
                    const producers = producersResponse.data;

                    const data = _.map(subUsers, (value) => ({ ...value, producer_name: _.find(producers, { id: value.producer_id }) ? _.find(producers, { id: value.producer_id }).name : '' }));

                    resolve({ subProducers: data });
                });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getSubProducerListDetails;
