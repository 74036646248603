import axios from 'axios';
import { getHaulingContractByDateRangeQuery } from './queries';
import getHaulerRatesForHaulingContract from './getHaulerRatesForHaulingContract';
import moment from 'moment-timezone';

export const getProducerHaulingContractListDetails = async (haulingId, producerId, startDate, endDate) => {
    if (!startDate || !endDate) {
        return { haulingContracts: [] };
    }
    try {
        const start = moment.isMoment(startDate) ? startDate.toDate() : startDate;
        const end = moment.isMoment(endDate) ? endDate.toDate() : endDate;

        const producerHaulingContractQuery = getHaulingContractByDateRangeQuery(
            {
                fields: ['hauler', 'producer', 'hauling_rate_details', 'start_date', 'end_date', 'pay_report', 'hauler_rates', 'contract_type', 'contract_max_charge_per_period', 'contract_name', 'paid_by_producer', 'exclude_pickups_before_date'],
                ...(!!haulingId && { haulingId }),
                ...(!!producerId && { producerId }),
            },
            start,
            end
        );
        const producerHaulingContractResponse = await axios.get(producerHaulingContractQuery);
        let producerHaulingContracts = producerHaulingContractResponse.data;
        if (haulingId) {
            producerHaulingContracts = producerHaulingContracts.filter((contract) => contract.hauler.id === haulingId);
        }
        if (producerId) {
            producerHaulingContracts = producerHaulingContracts.filter((contract) => contract.producer.id === producerId);
        }
        return { haulingContracts: producerHaulingContracts };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export const getSupplementalHaulingContractListDetails = async (haulingId, startDate, endDate) => {
    if (!startDate || !endDate) {
        return { haulingContracts: [] };
    }
    try {
        const start = moment.isMoment(startDate) ? startDate.toDate() : startDate;
        const end = moment.isMoment(endDate) ? endDate.toDate() : endDate;

        const supplementalHaulingContractQuery = getHaulingContractByDateRangeQuery(
            {
                fields: ['hauler', 'processor', 'route_id', 'producers_on_route', 'hauling_rate_details', 'start_date', 'end_date', 'pay_report', 'hauler_rates', 'contract_type', 'contract_name', 'paid_by_producer', 'exclude_pickups_before_date'],
                ...(!!haulingId && { haulingId }),
            },
            start,
            end,
            true
        );
        const supplementalHaulingContractResponse = await axios.get(supplementalHaulingContractQuery);
        let supplementalHaulingContracts = supplementalHaulingContractResponse.data;
        if (haulingId) {
            supplementalHaulingContracts = supplementalHaulingContracts.filter((contract) => contract.hauler.id === haulingId);
        }
        return { haulingContracts: supplementalHaulingContracts };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};
