import { authRoles } from 'app/auth';
import { FuseLoadable } from '@fuse';
import Split from '../../../../../../Split';

export const MilkPrice = {
    auth: authRoles.admin,
    routes: [
        {
            path: '/milk-prices/create-price',
            component: FuseLoadable({
                loader: () => import('./MutateMilkPrice'),
            }),
        },
        {
            path: '/milk-prices/edit-price/:id',
            component: FuseLoadable({
                loader: () => import('./MutateMilkPrice'),
            }),
        },
        {
            path: '/milk-prices/:date',
            component: FuseLoadable({
                loader: () => (Split.getBooleanTreatment('can_use_new_milk_prices_page') ? import('./MilkPrices') : import('./CreateMilkPriceApp')),
            }),
        },
        {
            path: '/milk-prices/weekly-advance',
            component: FuseLoadable({
                loader: () => import('./CreateMilkPriceApp'),
            }),
        },
        {
            path: '/milk-prices',
            component: FuseLoadable({
                loader: () => (Split.getBooleanTreatment('can_use_new_milk_prices_page') ? import('./MilkPrices') : import('./CreateMilkPriceApp')),
            }),
        },
    ],
};

export const HaulingRates = { auth: authRoles.admin, routes: [{ path: '/hauling-rates', component: FuseLoadable({ loader: () => import('./cdi/CreateCDIHaulingRateApp') }) }] };

export default [MilkPrice, HaulingRates];
