import React from 'react';
import { Alert, AlertTitle, Typography } from '@mui/material';

function FlattenArrayOfObjects(key) {
    return (prev, curr, index) => {
        if (curr instanceof Object)
            return {
                ...prev,
                ...Object.keys(curr).reduce((subPrev, subCurr) => {
                    return { ...subPrev, [`${key}-${index + 1}-${subCurr}`]: curr[subCurr] };
                }, {}),
            };

        return { ...prev, [`${key} - ${index + 1}`]: curr };
    };
}

function FormattedArrayErrors(errors) {
    if (errors instanceof Array)
        return (
            <ul>
                {errors.map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </ul>
        );
    if (errors instanceof Object)
        return (
            <ul>
                {Object.keys(errors).map((key) => {
                    if (errors[key] instanceof Array)
                        return (
                            <>
                                <li>{key} has the following errors</li>
                                {FormattedArrayErrors(
                                    errors[key].reduce(
                                        FlattenArrayOfObjects(key),
                                        {} //
                                    )
                                )}
                            </>
                        );
                    return <li>{errors[key].message ? errors[key].message : `Error with the ${key}`}</li>;
                })}
            </ul>
        );

    return <></>;
}

export default function ControllerErrorAlert({ title, errors, footer, severity = 'error', variant, sx }) {
    if (errors == null || errors.length === 0 || Object.keys(errors).length === 0) return <></>;

    return (
        <Alert severity={severity} variant={variant} sx={{ marginBottom: '10px', width: '100%', ...sx }}>
            <AlertTitle>{title}</AlertTitle>
            {typeof errors === 'string' ? <div>{errors}</div> : FormattedArrayErrors(errors)}
            <AlertTitle>{footer}</AlertTitle>
        </Alert>
    );
}
