import axios from 'axios';
import { getHaulingChargeQuery } from './queries';
import HaulingContractType from 'app/constants/hauling';

const getHaulingChargeListDetails = async (start, end, haulingId, producerId) => {
    try {
        const haulingChargeQuery = getHaulingChargeQuery({
            fields: ['producer', 'hauler', 'processor', 'producers_on_route', 'route_id', 'date', 'hauling_rate_details', 'monetary_value', 'approved_for_hauler', 'approved_for_producer', 'amount_paid', 'outstanding_balance', 'partner_total', 'contract_type', 'milk_pounds', 'contract_name', 'pay_report'],
            start,
            end,
            filterDeleted: true,
            ...(!!haulingId && { haulingId }),
            ...(!!producerId && { producerId }),
        });
        const haulingChargeResponse = await axios.get(haulingChargeQuery);
        let haulingCharges = haulingChargeResponse.data;
        if (haulingId) {
            haulingCharges = haulingCharges.filter((haulingCharge) => haulingCharge?.hauler?.id === haulingId && haulingCharge.approved_for_hauler);
        }
        if (producerId) {
            haulingCharges = haulingCharges.filter((haulingCharge) => haulingCharge?.producer?.id === producerId && haulingCharge.approved_for_producer);
        }

        return {
            producerHaulingCharges: haulingCharges.filter(({ contract_type }) => contract_type == null || contract_type === HaulingContractType.ProducerHaulerContract),
            supplementalHaulingCharges: haulingCharges.filter(({ contract_type }) => contract_type === HaulingContractType.HaulerProcessorRouteContract),
        };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getHaulingChargeListDetails;
