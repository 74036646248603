import _ from 'lodash';
import moment from 'moment-timezone';
/**
 * Converts raw query params to Express + AQP compliant query string
 *
 * @param {object} params
 * @returns {string} Express + AQP compliant query string
 */
// eslint-disable-next-line import/prefer-default-export
export const convertParamsToAQPQueryString = (params) => {
    try {
        const filters = [];
        const fields = [];
        if (params.populate) {
            const populateString = `populate=${Object.keys(params.populate).join(',')}`;
            Object.entries(params.populate).forEach((entry) => {
                const [reference, projectionFields] = entry;
                projectionFields.forEach((field) => {
                    fields.push(`${reference}.${field}`);
                });
            });
            filters.push(populateString);
        }
        if (params.filter) {
            // TODO this should be santitized with zod or something
            if (params.filter instanceof Object && Object.keys(params.filter).length > 0) {
                filters.push(`filter=${JSON.stringify(params.filter)}`);
            }
        }
        if (params.limit) {
            filters.push(`limit=${params.limit}`);
        }
        if (params.skip) {
            filters.push(`skip=${params.skip}`);
        }
        if (params.fields) {
            const fieldList = Object.keys(params.fields);
            fieldList.forEach((field) => {
                fields.push(`${params.fields[field] ? '' : '-'}${field === 'id' ? '_id' : field}`);
            });
        }
        if (!_.isEmpty(fields)) {
            let fieldString = 'fields=';
            fields.forEach((field) => {
                fieldString = `${fieldString},${field}`;
            });
            filters.push(fieldString.replace(',', ''));
        }
        if (params.order) {
            const [field, order] = params.order.split(' ');
            const orderFlag = order === 'ASC' ? '' : '-';
            filters.push(`sort=${orderFlag}${field}`);
        }
        if (params.where) {
            Object.entries(params.where).forEach((clause) => {
                const clauseName = clause[0] === 'id' ? '_id' : clause[0];
                if (typeof clause[1] === 'string' || clause[1] instanceof String) {
                    filters.push(`${clauseName}=${clause[1]}`);
                } else if (typeof clause[1] === 'number' || clause[1] instanceof Number) {
                    filters.push(`${clauseName}=${clause[1]}`);
                } else if ('inq' in clause[1]) {
                    let inqString = '';
                    clause[1].inq.forEach((item) => {
                        if (!!item && item.length === 24) {
                            inqString += `${item},`;
                        }
                    });
                    if (inqString !== '') {
                        filters.push(`${clauseName}=${inqString.slice(0, -1)}`);
                    } else {
                        filters.push(`${clauseName}=000000000000000000000000`);
                    }
                } else if ('eq' in clause[1]) {
                    filters.push(`${clauseName}=${clause[1].eq}`);
                } else if ('neq' in clause[1]) {
                    filters.push(`${clauseName}!=${clause[1].neq}`);
                } else if ('between' in clause[1]) {
                    // some cases a moment obj requires hard casting of date/time format, so convert all to same format
                    const start = `date(${moment(clause[1].between[0]).toISOString()})`;
                    const end = `date(${moment(clause[1].between[1]).toISOString()})`;
                    filters.push(`${clause[0]}>=${start}&${clause[0]}<=${end}`);
                } else if ('lt' in clause[1]) {
                    filters.push(`${clauseName}<${clause[1].lt}`);
                } else if ('lte' in clause[1]) {
                    filters.push(`${clauseName}<=${clause[1].lte}`);
                } else if ('gt' in clause[1]) {
                    filters.push(`${clauseName}>${clause[1].gt}`);
                } else if ('gte' in clause[1]) {
                    filters.push(`${clauseName}>=${clause[1].gte}`);
                }
            });
        }
        let queryString = '';
        if (filters.length > 0) {
            filters.forEach((filter) => {
                queryString += `${filter}&`;
            });
            queryString = queryString.slice(0, -1);
        }
        return queryString;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

/**
 * Transforms a map into a query string {key1: value1, key2: value2 } => ?key=value&key2=value2
 * @param queryObject
 * @returns {string}
 */
export const transformObjectToQueryString = (queryObject) => {
    let queryString = '';

    if (queryObject && Object.keys(queryObject).length > 0) {
        queryString += `${Object.keys(queryObject)
            .map((key) => (queryObject[key] ? `&${key}=${queryObject[key]}` : ''))
            .join('')}`;
    }

    return queryString;
};
