import { FuseLoadable } from '@fuse';

export const Index = {
    auth: ['admin'],
    routes: [
        {
            path: '/milk-prices/weekly-advance/create-rate',
            component: FuseLoadable({
                loader: () => {
                    return import('./MutateWeeklyMilkPrice');
                },
            }),
        },
        {
            path: '/milk-prices/weekly-advance/edit-rate/:id',
            component: FuseLoadable({
                loader: () => {
                    return import('./MutateWeeklyMilkPrice');
                },
            }),
        },
    ],
};

export default [Index];
