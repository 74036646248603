import Split from '../../../../../Split';
import { AbilityBuilder, PureAbility } from '@casl/ability';

function UserManagementPermissions(user) {
    const { can, rules } = new AbilityBuilder(PureAbility);
    const {
        role,
        data: { permissions },
    } = user;
    const split = Split.getBooleanTreatment('can_access_plant_management');

    if (split) {
        // list processors actions
        if (role === 'admin' && permissions.includes('ListProcessors')) {
            can('mutate', 'processor');
        }

        // list producer actions
        if (role === 'admin' && permissions.includes('ListProducers')) {
            can('mutate', 'producer');
        }

        // list hauler actions
        if (role === 'admin' && permissions.includes('ListHaulingCompanies')) {
            can('mutate', 'transport');
        }
    }

    return rules;
}

export default UserManagementPermissions;
