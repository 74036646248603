import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
/* eslint-disable-next-line */
import classNames from 'classnames';
import { FuseNavigation } from '@fuse';
import { mui5ThemeObject } from '../../../styles/mm-theme-fuse';

const Navigation = ({ navigation, layout, dense, className }) => <FuseNavigation style={{ backgroundColor: mui5ThemeObject.palette.secondary.extraDark }} className={classNames('navigation', className)} navigation={navigation} layout={layout} dense={dense} />;

function mapStateToProps({ fuse }) {
    return { navigation: fuse.navigation };
}

Navigation.defaultProps = { layout: 'vertical' };

export default withRouter(connect(mapStateToProps)(Navigation));
