import axios from 'axios';
import { getWeeklyMilkPriceQuery } from './queries';
import moment from 'moment-timezone';

const getWeeklyMilkPrices = (date) =>
    new Promise((resolve, reject) => {
        const query = getWeeklyMilkPriceQuery({
            startDate: moment(date).startOf('month').toISOString(),
            endDate: moment(date).endOf('month').toISOString(),
        });
        const request = axios.get(query);

        request
            .then((response) => {
                resolve(response.data);
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to get milk prices'));
            });
    });

export default getWeeklyMilkPrices;
